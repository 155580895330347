import * as React from "react";
import Layout from "../components/layout";
import Head from "../components/head";
import { graphql, useStaticQuery } from "gatsby";

const NotFound = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          author
        }
      }
    }
  `);

  return (
    <Layout>
      <div className="p-2">
        <Head title={`404 | ${data.site.siteMetadata.title}`} />
        <h1>
          Woops!...{" "}
          <span role="img" aria-label="emoji">
            🙈
          </span>
        </h1>
        <p>
          You somehow ended here, either manually or by clicking some link I
          provided. Either way, there's no content here. Use the menu to head
          back to safety.
        </p>
      </div>
    </Layout>
  );
};

export default NotFound;
